<!--
 * @Author       : Hugo
 * @Date         : 2022-04-07 10:21:25
 * @LastEditTime: 2022-05-25 13:36:49
 * @LastEditors: hugo 1634765756@qq.com
 * @FilePath     : /miaohang/src/components/PopUp/SelectUsers.vue
 * @Description  : 
 * @^_^          : 
-->
<template>
  <div
    class="components_popup_select_users"
  >
    <div class="mask d-flex align-center justify-center"
      :style="{zIndex: index}"
    >
      <div class="wrapper bbox d-flex flex-column">
        <div class="title_group">
          <div class="title">{{title}}</div>
          <img src="@/assets/images/common/close.png" alt="" class="close cp" @click.stop="doClose">
        </div>
        <div class="search_group">
          <div class="search_box">
            <img src="@/assets/images/common/search_icon.png" alt="" class="search_icon cp">
            <el-input v-model="input" placeholder="搜索" class="search"></el-input>
            <img src="@/assets/images/common/clear_icon.png" alt="" class="clear_icon cp" @click="input = ''">
          </div>
        </div>
        <div class="container_group">
          <!-- 待选 -->
          <div class="select_group un_sel">
            <div
              class="select_all_group cp"
              :class="{on: is_all}"
              @click="doSelectAll"
              v-if="select_all">
              <div class="text d-flex align-center">
                <div class="flex-shrink-0">全选</div>
                <template
                >
                  <div>（</div>
                  <loading-icon size="12px"
                    v-if="loading"
                  ></loading-icon>
                </template>
                <div class="">{{loading ? '' : list.length}}）</div>
              </div>
              <div class="select_box">
                <div class="select cp"></div>
              </div>
            </div>
            <template v-for="(item, index) in list" >
              <div class="unit padding"  :key="`index${index}`"
                v-show="!item.hide &&  ((input && item.searchName) || !input) "
              >
                <div class="create_dialog_select_unit cp" :class="{'on': item.selected}" @click="selectItem(item)">
                  <div class="name_group">
                    <img :src="`${$avatar_url}${item.thumbnail}@!small200`" alt="" class="avatar">
                    <div class="name" v-html=" item.searchName || item.friendRemark || item.userName ">{{item.userName}}</div>
                  </div>
                  <div class="select_box">
                    <div class="select cp" ></div>
                  </div>
                </div>
              </div>
            </template>
          </div>
          <!-- 已选 -->
          <div class="selected_group un_sel" >
            <template v-for="(item, index) in selected_list">
              <div
                class="unit padding"  :key="index"
              >
                <div class="create_dialog_selected_unit">
                  <div class="name_group">
                    <img :src="`${$avatar_url}${item.thumbnail}@!small200`" alt="" class="avatar">
                    <div class="name" v-html="item.friendRemark || item.userName ">{{item.userName}}</div>
                  </div>
                  <div class="select_box" v-if="item.deletable">
                    <div class="select cp" @click="doDelete(item)"></div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
        <div class="fun_group">
          <button class="btn btn_dark sure" @click="doSure()">确定</button>
          <button class="btn btn_light cancel" @click="doClose()">取消</button>
        </div>
      </div>
    </div>
    
    
  </div>
</template>
<script>
import LoadingIcon from '@/components/basic/loadingIcon.vue'
export default {
  components:{
    LoadingIcon,
  },
  props:{
    title: {
      type: String,
      default: '选择组内联系人'
    },
    /**
     * 选择列表 
     * 已选择 selected
     * 可删除 deletable
     * 隐藏 hide 
     */
    list: {
      type: Array,
      default: function(){
        return [];
      }
    },
    search: {
      type: Boolean,
      default: true,
    },
    select_all: {
      type: Boolean,
      default: false,
    },
    // 全部操作权限
    full_control: {
      type: Boolean,
      default: false,
    },
    total_num :{
      type: Number,
      default: undefined,
    },
  },
  watch:{
    /**搜索 */
    input: function(val){
      val = val.trim();
      this.filterContact(val);
    },
  },
  data(){
    return{
      index: 1,
      input: '',
    }
  },
  computed: {
    selected_list(){
      const {list} = this;
      let selected_list = [];
      if(list && list.length){
        list.forEach(item=>{
          if(item.selected && !item.hide){
            selected_list.push(item);
          }
        })
      }
      return selected_list;
    },
    is_all(){
      const {list, input} = this;
      let is_all = true;
      if(list && list.length){
        list.forEach(item=>{
          if(!item.hide && !item.selected){
            const {searchName} = item
            if(!(input && !searchName) || !input){  //  搜索时  || 未搜索时
              is_all = false;
            }
          }
        })
      }else{
        is_all = false;
      }
      return is_all;
    },
    loading(){
      const {list, total_num} = this
      if(total_num){
        if(list.length != total_num){
          return true
        }
      }
      return false
    }
  },
  methods: {
    doSelectAll(){
      const {list, is_all, input,loading}  = this;
      if(loading){
        this.$notice({desc:'正在加载请稍后'})
        return
      }
      console.log(list)
      if(list && list.length){
        list.forEach(item=>{
          if(!input || (input && item.searchName)){
            this.$set(item, 'selected', !is_all)
          }
        })
      }
    },
    selectItem(item){
      const {full_control} = this;
      const {deletable, selected} = item;
      console.log(item,item.selected);
      if(selected){
        if(!deletable && !full_control){
          this.$notice({desc: '没有操作权限'});
          return;
        }
      }

      // item.selected = !selected;
      this.$set(item, 'selected', !selected)

    },
    doDelete(item){
      const {list} = this;
      const unit = list.find(element => {
        return element.userId == item.userId;
      })
      if(unit !== undefined){
        this.$set(unit, 'selected', false);
      }
    },
    closeSwitch(){

    },
    doClose(){
      this.$emit('handleClose')
    },
    doSure(){
      this.$emit('handleSure', this.selected_list);
    },
    /**
     * @description: 筛选联系人
     * @param {String} val 搜索字段 
     * @return {Object} 选择列表 
     */
    filterContact(val){
      let list = this.list;
      // // console.log(list);
      
      // 循环用户 如果用户名或昵称匹配得上 新增字段 searchName
      // list.forEach( item => {
      if(val){
        list.forEach((citem,index)=>{
          if(citem.userName.toLowerCase().indexOf(val.toLowerCase()) != -1){
            citem.searchName = this.$tools.highlightKeywords(citem.userName, val);
          }else if(citem.friendRemark && citem.friendRemark.toLowerCase().indexOf(val.toLowerCase()) != -1){
            citem.searchName = this.$tools.highlightKeywords(citem.friendRemark, val);
          }else{
            citem.searchName = '';
          }
          this.$set(this.list[index], 'searchName',  citem.searchName)
        })
      }else{
        list.forEach((citem,index)=>{
          citem.searchName = '';
          this.$set(this.list[index], 'searchName',  citem.searchName)
        })
        
      }
      return list;
    },
  },

  mounted(){
    console.log(this.list)
    const zindex = this.$utils.getZIndex();
    this.index = zindex;
  }
}
</script>
<style lang="scss" scoped>
.components_popup_select_users{
  .mask{
    position: fixed;
    width: 100%;
    height: 100%;
    top:0;
    left: 0;
    background-color: rgba(0,0,0,0.3);
  }
  .wrapper{
    width:60%;
    height:768px;
    max-width: 1200px;
    max-height: 80%;
    background-color: #fff;
    box-shadow:0px 0px 40px 0px rgba(0, 0, 0, 0.16);
    border-radius:4px;
    overflow: hidden;
  }
  .title_group{
    @include bbox;
    flex-shrink: 0;
    text-align: center;
    width:100%;
    position: relative;
    z-index: 2;
    padding-top:36px;
    .title{
      font-size:18px;
      
      font-weight:400;
      color:rgba(51,51,51,1);
      position: relative;
      z-index: 2;
    }
    .close{
      position: absolute;
      z-index: 3;
      top: 42px;
      right: 30px;
      width: 11px;
      height: 11px;
    }
  }
  .search_group{
    flex-shrink: 0;
    padding: {
      top:39px;
      bottom: 30px;
    }
    .search_box{
      @include flexsb;
      @include bbox;
      padding: {
        left: 30px;
        right: 20px;
      }
      margin:0 auto;
      width:400px;
      height:40px;
      background:rgba(243,243,243,1);
      border-radius:20px;
      .search_icon{}
      .search{
        border:none;
        background-color: transparent;
        .el-input__inner{
          border:none;
          background-color: transparent;
        }
      }
      .clear_icon{}
    }
  }
   .container_group{
    @include bbox;
    @include flex;
    align-items: stretch;
    height:100%;
    max-height: 100%;
    flex:1;
    width:100%;
    overflow: hidden;
    border: {
      top: 2px solid #f0f0f0;
      bottom: 2px solid #f0f0f0;
    }
    .padding{
      padding: {
        top: 10px;
        bottom: 10px;
        left:37px;
        right:46px;
      }
    }
    .select_group{
      @include bbox;
      @include hidescrollbar;
      padding: 20px 0;
      overflow-y: auto;
      width:50%;
      border-right: 1px solid #f0f0f0;
      .add_new_group{
        @include flex;
        padding-top: 10px;
        .img{
          width: 44px;
          height: 44px;
          border-radius: 4px;
          display: block;
        }
        .text{
          padding-left: 16px;
          font-size:14px;
          
          font-weight:400;
          color:rgba(51,51,51,1);
        }
      }
      .firstname_group{
        .title{
          @include bbox;
          font-size:14px;
          
          font-weight:400;
          color:rgba(153,153,153,1);
          text-transform: uppercase;
        }
        .unit{
          @include bbox;
          @include transition;
          background-color:#fff;
          &:hover{
            background-color: $background_hover_color;
          }
        }
      }
      .select_all_group{
        @include flexsb;
        @include bbox;
        padding: {
          left: 37px;
          right: 46px;
          top: 6px;
          bottom: 10px;
        }
        .text{
          font-size: 14px;
        }
        &.on{
          .select_box{
            .select{
              border:none;
              background-image: url(~@/assets/images/create_dialog/selected.png);
              background-size: 100%;
            }
          }
        }
        .select_box{
          width:18px;
          height:18px;
          .select{
            @include bbox;
            // @include transition;
            width: 100%;
            height: 100%;
            border:2px solid rgba(153,153,153,1);
            border-radius:50%;
          }
        }

      }
    }
    .selected_group{
      @include bbox;
      @include hidescrollbar;
      flex-shrink: 0;
      padding: {
        top: 20px;
        bottom: 20px;
      }
      overflow-y: auto;
      width:50%;
      border-left: 1px solid #f0f0f0;
      .unit{
        @include bbox;
        @include transition;
        background-color:#fff;
        &:hover{
          background-color: $background_hover_color;
        }
      }
    }
  }
  .fun_group{
    @include flex;
    padding:20px 0;
    flex-shrink: 0;
    .btn{
      @include transition;
      width:160px;
      line-height:50px;
      background:rgba(204,204,204,1);
      border-radius:4px;
      margin: 0 20px;
      font-size:18px;
      outline: none;
      border: none;
      font-weight:400;
      text-align: center;
      color:#fff;
      cursor: pointer;
    }
    .sure{
      background-color: $main_blue;
      &:hover{
        background-color: #004080;
      }
    }
    .cancel{
      background-color:rgba(0,84,166,0.1);
      color: $main_blue;
      &:hover{
        background-color: rgba(0,84,166,0.2);
      }
    }
  }
}
@media screen and (max-height: 800px){
  .select_task_container{
    .title_group{
      padding-top:16px;
      .title{
        font-size: 14px;
      }
      .close{
        top:22px;
      }
    }
    .search_group{
      padding: {
        top: 10px;
        bottom: 10px;
      }
      .search_box{
        padding: {
          left: 20px;
          right: 10px;
        }
        width: 300px;
        height:30px;
        border-radius:15px;
      }
    }
    .container_group{
      .padding{
        padding: {
          top: 6px;
          bottom: 6px;
          left:37px;
          right:46px;
        }
      }
      .select_group{
        padding: 10px 0;
      }
      .selected_group{
        padding: 10px 0;
      }
    }
    .fun_group{
      padding:10px 0;
      .btn{
        width:120px;
        line-height:30px;
        margin: 0 10px;
        font-size:14px;
      }
      .sure{
        background-color: $main_blue;
        &:hover{
          background-color: #004080;
        }
      }
      .cancel{
        background-color:rgba(0,84,166,0.1);
        color: $main_blue;
        &:hover{
          background-color: rgba(0,84,166,0.2);
        }
      }
    }
  }
}
.create_dialog_select_unit{
  @include bbox;
  @include flexsb;
  @include transition;
  // background-color: #fff;
  width:100%;
  padding: {
    // left: 37px;
    // right: 46px;
    // top: 11px;
    // bottom: 11px;
  }
  // &:hover{
  //   background-color:#f4f4f4;
  // }
  &.on{
    .select_box{
      .select{
        border:none;
        background-image: url(~@/assets/images/create_dialog/selected.png);
        background-size: 100%;
      }
    }
  }
  .name_group{
    @include flex;
    @include bbox;
    width:100%;
    flex: 1;
    padding-right: 15px;
    overflow: hidden;
    .avatar{
      width: 44px;
      height: 44px;
      border-radius: 4px;
      flex-shrink: 0;
    }
    .name{
      @include bbox;
      padding-left: 16px;
      width:100%;
      flex:1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size:14px;
      
      font-weight:400;
      color:rgba(51,51,51,1);
    }
  }
  .select_box{
    width:18px;
    height:18px;
    .select{
      @include bbox;
      // @include transition;
      width: 100%;
      height: 100%;
      border:2px solid rgba(153,153,153,1);
      border-radius:50%;
    }
  }
}
@media screen and (max-height: 800px){
  .create_dialog_select_unit{
    .name_group{
      .avatar{
        width: 30px;
        height: 30px;
      }
    }
  }
}
.create_dialog_selected_unit{
  @include bbox;
  @include flexsb;
  @include transition;
  // background-color: #fff;
  width:100%;
  // padding: {
  //   left: 37px;
  //   right: 46px;
  //   top: 11px;
  //   bottom: 11px;
  // }
  // &:hover{
  //   background-color:#f4f4f4;
  // }
  .name_group{
    @include flex;
    @include bbox;
    width:100%;
    flex: 1;
    padding-right: 15px;
    overflow: hidden;
    .avatar{
      width: 44px;
      height: 44px;
      border-radius: 4px;
      flex-shrink: 0;
    }
    .name{
      @include bbox;
      padding-left: 16px;
      width:100%;
      flex:1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size:14px;
      
      font-weight:400;
      color:rgba(51,51,51,1);
    }
  }
  .select_box{
    width:18px;
    height:18px;
    .select{
      @include bbox;
      @include transition;
      width: 100%;
      height: 100%;
      border-radius:50%;
      border:none;
      background-image: url(~@/assets/images/create_dialog/delete.png);
      background-size: 100%;
    }
  }
}
@media screen and (max-height: 800px){
  .create_dialog_selected_unit{
    .name_group{
      .avatar{
        width: 30px;
        height: 30px;
      }
    }
  }
}
</style>

<style lang="css">
  .select_task_container .search .el-input__inner{
    border:none;
    background-color: transparent;
    font-size:14px;
    
    font-weight:400;
    color:#666;
    padding: 0 10px;
  }
</style>